<template>

  <span>
    <b-card-code title="" no-body>
      <b-card-body
        ><h3>Search Member By Email</h3>
      </b-card-body>

      <b-card-body>
        <b-row>
          <b-col md="10" xl="10" class="mb-1">
            <!-- basic -->
            <b-form-group label="Cari" label-for="basicInput">
              <b-form-input
                id="basicInput"
                placeholder="Email"
                v-model="email"
              />
            </b-form-group>
          </b-col>

          <b-col lg="2" md="2" class="mb-50">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click="getMember()"
            >
              <feather-icon icon="SearchIcon" class="mr-25" />
              <span>Search</span>
            </b-button>
          </b-col>
          <b-col lg="10" md="10" class="mb-50" >
            <b-alert
              variant="danger"
              show
              v-if="!isMember"
            >
              <div class="alert-body">
                <span><strong>Email yang diinput harus terdaftar sebagai user verified!</strong></span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        
        
      </b-card-body>
        
      <!-- table -->
      
      
    </b-card-code>
    <b-card-code title="" no-body v-if="isMember">
      <b-card-body
        ><h3>Mentor Register Form</h3>
      </b-card-body>

      <b-card-body>
        <b-row>
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Occupation" label-for="basicInput">
              <b-form-input
                id="basicInput"
                placeholder="Occupation"
                v-model="occupation"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Bio" label-for="bio">
              <b-form-input
                id="bio"
                placeholder="Bio"
                v-model="bio"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="No Telp" label-for="telp">
              <b-form-input
                id="telp"
                placeholder="+62"
                v-model="no_telp"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Link" label-for="link">
              <b-form-input
                id="link"
                placeholder="https://"
                v-model="link"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Linkedin" label-for="link">
              <b-form-input
                id="link"
                placeholder="https://"
                v-model="link_linkedin"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Instagram" label-for="link">
              <b-form-input
                id="link"
                placeholder="https://"
                v-model="link_ig"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Link Book Mentor" label-for="link">
              <b-form-input
                id="link"
                placeholder="https://"
                v-model="link_book_mentor"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Photo" label-for="photo">
              <b-form-file
                id="photo"
                accept="image/*"
                v-model="currentPhoto"
                @change="onPhotoChange($event)"
              />
            </b-form-group>

            <img :src="photo" alt="" width="100px" v-if="photo!=null">
          </b-col>
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Topic" label-for="status">
              <v-select
                v-model="selectedTopic"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="topics"
              />
            </b-form-group>
          </b-col>
         
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Minat" label-for="minat">
              <v-select
                v-model="selectedMinat"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="topik"
                :options="minats"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <p>Schedules</p> 
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-50"
              @click="openModalCreateSchedule"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50 "
              />
              <span class="align-middle">Add Schedule</span>
            </b-button>
            <br>
            <b-table responsive="sm" :items="schedules" :fields="getFields"> 
              <template #cell(delete)="data">
                <b-button
                  variant="gradient-danger"
                  class="btn-icon"
                  @click="deleteSchedule(data.index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>

          <b-col lg="12" md="12" class="mb-50">
            <b-button
              class="mt-3"
              variant="outline-primary"
              block
              @click="registerMentor()"
              :disabled="!isMember"
            >
              <feather-icon icon="SendIcon" /> Submit
            </b-button>
          </b-col>
        </b-row>

        <b-button-toolbar
          class="d-flex justify-content-between flex-wrap pt-0"
          aria-label="Toolbar with button groups and dropdown menu"
        >
        
        </b-button-toolbar>
      </b-card-body>

      <!-- table -->
      
      
    </b-card-code>
    <b-modal id="modal-create-schedule" hide-footer no-close-on-backdrop>
      <template #modal-title> Input Schedule </template>
      
      <b-form-group label="Day" label-for="type">
        <v-select
          v-model="selectedDay"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="days"
          required
        />
      </b-form-group>
    
        <b-form-group label="Start" label-for="start">
          <v-select
            v-model="selectedStart"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="time"
            :options="times"
          />
        </b-form-group>
        <b-form-group label="End" label-for="end">
          <v-select
            v-model="selectedEnd"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="time"
            :options="times"
          />
        </b-form-group>
      

      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        :disabled="selectedDay == null || selectedEnd==null || selectedStart==null "
        @click="addSchedule()"
      >
        <feather-icon icon="PlusIcon" /> Add
      </b-button>
    </b-modal>
  </span>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea,BTable,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal, BFormFile,BAlert
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormFile,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BTable,
    BAlert
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        0: 'Register', 1: 'Verify', 2: "Can't find", 3: 'Input wrong data'
      },
      {
        0: 'light-primary', 1: 'light-success', 2: 'light-warning', 3: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],
      search: null,
      email: "",
      member:null,
      minats:[],
      topics:[],
      selectedTopic:null,
      selectedMinat:null,

      occupation:null,
      bio:null,
      no_telp:null,
      link:null,
      link_ig:null,
      link_linkedin:null,
      link_book_mentor:null,
      schedules:[],
      photo:null,
      currentPhoto:null,
      schedules:[],
      days:[{name:'Senin'}, {name:'Selasa'}, {name:'Rabu'}, {name:'Kamis'}, {name:'Jumat'}, {name:'Sabtu'}, {name:'Minggu'}],
      selectedStart:{time:"01:00"},
      selectedEnd:{time:"02:00"},
      selectedDay:{name:'Senin'},
      isMember:false,
      times:[]

    }
  },
  methods: {
    openModalCreateSchedule(){
       this.$bvModal.show("modal-create-schedule")
    },
    addSchedule(){
      var newSchedule = {
        day:this.selectedDay.name,
        start:this.selectedStart.time,
        end:this.selectedEnd.time

      }
      this.schedules.push(newSchedule)
      // this.$bvModal.hide("modal-create-schedule")
    },
    deleteSchedule(indexSchedule){
      console.log(indexSchedule)
      this.schedules.splice(indexSchedule,1)
    },
    validateHhMm(value) {
      var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
      return isValid;
    },
    onPhotoChange(e) {
      const { files } = e.target
      if (files.length) {
        this.createImage(files[0], result => {
          this.photo = result
        })
      }
    },
    createImage(file, cb) {
      const reader = new FileReader()

      reader.onload = e => {
        cb(e.target.result)
      }
      reader.readAsDataURL(file)
    },
    async registerMentor() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        
        var input = { 
            member_id: this.member.id, 
            occupation:this.occupation,
            bio:this.bio,
            link : this.link,
            link_book_mentor : this.link_book_mentor,
            link_ig : this.link_ig,
            link_linkedin: this.link_linkedin,
            photo : this.photo,
            topics: this.selectedTopic.map( (item) => item.id),
            minats: this.selectedMinat.map( (item) => item.id),
            schedules: this.schedules
          }
        const { } = await API.mentorship.register(input)
        this.handleMsgSuccess('Berhasil daftar Mentor')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },
    async getMember() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const { data } = await API.mentorship.member({ email: this.email})
        console.log(data)
      
        this.member = data
        if(this.member != null){
            this.occupation = this.member.occupation
            this.bio = this.member.bio
            this.no_telp = this.member.no_telp
            this.link = this.member.link
            this.link_linkedin = this.member.link_linkedin
            this.link_book_mentor = this.member.link_book_mentor
            this.link_ig = this.member.link_ig
            this.selectedTopic = this.member.mentorship_topics
            this.selectedMinat = this.member.interests
            this.photo = this.member.photo
            this.schedules = this.member.schedules
            this.isMember = true
        }
        this.handleMsgSuccess('Email terdaftar sebagai alumni')
      } catch (error) {
        console.log(error)
        this.isMember = false
        this.member = null
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async getMinat() {

      try {
        const { data } = await API.mentorship.minat()
        this.minats = data.data

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
    },
    async getTopic() {

      try {
        const { data } = await API.mentorship.topic()
        this.topics = data

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
    },
    // resetMember(){
    //     this.member=null
    //     this.occupation = null
    //     this.bio = null
    //     this.no_telp = null
    //     this.link = null
    //     this.link_linkedin = null
    //     this.link_book_mentor = null
    //     this.link_ig = null
    //     this.selectedTopic = []
    //     this.selectedMinat = []
    //     this.photo = null
    //     this.schedules = []
    // },
    setTimes(){
      var minutesArray = [];

      for (var i = 0; i < 24; i++) {
          for (var j = 0; j < 60; j+=15) {
              var time = (i < 10 ? '0' : '') + i + ':' + (j < 10 ? '0' : '') + j;
              minutesArray.push({time: time});
          }
      }

      this.times = minutesArray
    }



  },
  computed: {
    getFields() {
      const fields = [
        { key: 'day', label: 'Hari' },
        'start',
        'end',
        'delete'
      ]

      return fields
    },
  },
  mounted() {
    this.getMinat()
    this.getTopic()
    this.setTimes()
  },
  watch: {
    // currentPage() {
    //   this.getPendidikan()
    // },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
